import React from "react";
import { Redirect, Route, Switch,withRouter} from "react-router-dom";
import {ListsWidget10, ListsWidget11, AdvanceTablesWidget1, MixedWidget6,MixedWidget16, MixedWidget10, MixedWidget11, MixedWidget12, TilesWidget1, TilesWidget3, TilesWidget10, TilesWidget11, TilesWidget12, TilesWidget13, TilesWidget14} from "../widgets";

import {LIVE_API_URL,CONFIG_WITHOUT_TOKEN,API_URL,CONFIG_WITH_TOKEN} from '../../../appconstants/appconstantsurl';

import AccessModal from '../../../app/modules/ErrorMessage/AccessModal';

import PopupMessageHomePage from '../../../app/modules/MessageBoard/PopupMessageHomePage'

import Axios from 'axios';
import Qs from 'querystring';

let  first_title = "Sales";
let first_ammount = "$3000";
let first_up = "up";
let first_percent = "3%";

let second_title= "Units";
let second_ammount = "3200";
let second_down = "down";
let second_percent = "10%";


let third_title = "Category Rank";
let third_ammount = "#2";
let third_down  = "up";
let third_percent = "6"

let four_title = "Units 7 days";
let four_ammount = "40";

let five_title =" Units 30 days";
let five_ammount = "80";

let six_title = 'Units 60 days';
let six_ammount = "120";

let sev_title = "Cat Ranks 7 days";
let sev_ammount = "1";

let eight_title = "Cat Ranks 30 days";
let eight_ammount = "2";

let nine_title = "Cat Ranks 60 days";
let nine_ammount = "2";


class Demo2Dashboard extends React.Component {

    state={
        data:"",
        weeklyUnitSales:"",
        loading:false,
        redirect_to:"",
        show_modal:false,
        modal_error_message:"",
        show_popup_message:false,
        messageData:""
    }
     getuserDetials(){
        Axios.get(API_URL +  '/apis/user',CONFIG_WITH_TOKEN)
        .then(function (response) {
              if(response.data.error == 0){
                  if(response.data.user.company !== undefined && response.data.user.company !== ""){
                          if(response.data.user.user_plan_name !== null && response.data.user.user_plan_name !== undefined){
                              localStorage.setItem('user_plan_name',response.data.user.user_plan_name);
                          }
                          localStorage.setItem('company_name',response.data.user.company.name);
                          localStorage.setItem('company_ecrs_name',response.data.user.company.ECRS_name !== null && response.data.user.company.ECRS_name !== undefined ? response.data.user.company.ECRS_name:"");
                          localStorage.setItem('company_status_review',response.data.user.company.status_review);
                          localStorage.setItem('company_status',response.data.user.company.status);
                  }
                  localStorage.setItem('user_type',response.data.user.type);
                  localStorage.setItem('user_first_name',response.data.user.first_name);
                  localStorage.setItem('user_last_name',response.data.user.last_name);
                  localStorage.setItem('companies_count',response.data.user.companies_count);
              }else if(response.data.error == 1){
              }else if (response.data.error == 3){
                  localStorage.clear();
                  window.location.href = "/auth/login";
              }

        })
        .catch(function (error) {
            console.log(error.message);
        })
    }
    componentDidMount(){
        let user_type_who = localStorage.getItem("company_user_type");
        let user_show_message = sessionStorage.getItem("close_popup");
        if(user_show_message == undefined || user_show_message == null){
            this.setState({show_popup_message:true})
        }

        if(user_type_who !== "broker"){
            this.getuserDetials();
        }

        this.setState({loading:true});
        let reuqest_data = {
            action:"dashboard"
        }
        Axios.post(API_URL + '/dashboard/apis.php',Qs.stringify(reuqest_data),CONFIG_WITH_TOKEN)
        .then((response) => {
            if(response.data.error == 0){
                this.setState({data:response.data.data,weeklyUnitSales:response.data.data.WeeklyUnitSales,loading:false})
            }else if (response.data.error == 2) {
                let check_who_is_login = localStorage.getItem("user_type");
                if(check_who_is_login !== undefined && check_who_is_login !== null){
                    if(check_who_is_login == "company"){
                        this.setState({show_modal:true,modal_error_message:response.data.message});
                    }else if(check_who_is_login == "admin") {
                        this.setState({show_modal:true,modal_error_message:response.data.message});
                    }else if(check_who_is_login == "broker") {
                        this.setState({show_modal:true,modal_error_message:response.data.message});
                    }
                }
            }else if (response.data.error == 3) {
                localStorage.clear();
                window.location.href='/auth/login';

            }
        })
        .catch((error) => {

        });
    }
    hidemodal = () =>{
        this.setState({show_modal:false})
    }
    popupMessage = () => {
        this.setState({show_popup_message:false})
        sessionStorage.setItem("close_popup","yes");
    }
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    }
    render(){
        const {data,weeklyUnitSales,loading,redirect_to} = this.state;
        return(
          <>
              {/* begin::Row */}
              <div className="row" style={{marginRight:"20px",float:"right"}}>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </div>
              <div className="row" style={{marginTop:"20px"}}>
                <div className="col-xl-3" style={{justifyContent:"center",alignItems:"center",display:"flex",color:"#ffffff"}}>
                    <h1>Last Week</h1>
                </div>

                  <div className="col-xl-3">
                      <TilesWidget11 className="gutter-b" baseColor="dark" widgetHeight="150px"  title={first_title} amount={this.state.data.TotalSales7Days1 ? "$" + this.numberWithCommas(parseInt(this.state.data.TotalSales7Days1)):null} up_down={this.state.data.TotalSales7Direction ? this.state.data.TotalSales7Direction:null} percent={this.state.data.TotalSales7Percent !== "" && this.state.data.TotalSales7Percent !== undefined  ? this.state.data.TotalSales7Percent + "%":""} />
                  </div>
                  <div className="col-xl-6">
                      <div className="row">
                          <div className="col-xl-6">
                              <TilesWidget11 className="gutter-b" baseColor="dark" widgetHeight="150px" title={second_title} amount={this.state.data.TotalUnits7Days1 ? this.numberWithCommas(parseInt(this.state.data.TotalUnits7Days1)):null} up_down={this.state.data.TotalUnits7Direction ? this.state.data.TotalUnits7Direction: null} percent={this.state.data.TotalUnits7Percent !== "" && this.state.data.TotalUnits7Percent !== undefined ? this.state.data.TotalUnits7Percent + "%":""}
                               />
                          </div>
                          <div className="col-xl-6">
                              <TilesWidget11 className="gutter-b" baseColor="dark" widgetHeight="150px" title={third_title} amount={this.state.data.Category7RankNow ? "#" + this.state.data.Category7RankNow:""} brand_name={this.state.data.Pi1_Description ? this.state.data.Pi1_Description:""} />
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row">
                <div className="col-xl-3" style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
                    <h1>Last 4 Weeks</h1>
                </div>
                  <div className="col-xl-3">
                      <TilesWidget11 className="gutter-b" baseColor="dark" widgetHeight="150px"  title={first_title} amount={this.state.data.TotalSales30Days1 ? "$" + this.numberWithCommas(parseInt(this.state.data.TotalSales30Days1)):null} up_down={this.state.data.TotalSales30Direction ? this.state.data.TotalSales30Direction:""} percent={this.state.data.TotalSales30Percent !== "" && this.state.data.TotalSales30Percent !== undefined ? this.state.data.TotalSales30Percent + "%":""}  />
                  </div>
                  <div className="col-xl-6">
                      <div className="row">
                          <div className="col-xl-6">
                              <TilesWidget11 className="gutter-b" baseColor="dark" widgetHeight="150px" title={second_title} amount={this.state.data.TotalUnits30Days1 ? this.numberWithCommas(parseInt(this.state.data.TotalUnits30Days1)):null}  up_down={this.state.data.TotalUnits30Direction ? this.state.data.TotalUnits30Direction:""} percent={this.state.data.TotalUnits30Percent !== "" && this.state.data.TotalUnits30Percent !== undefined ? this.state.data.TotalUnits30Percent + "%":""} />
                          </div>
                          <div className="col-xl-6">
                              <TilesWidget11 className="gutter-b" baseColor="dark" widgetHeight="150px" title={third_title} amount={this.state.data.Category30RankNow ? "#" + this.state.data.Category30RankNow:null} brand_name={this.state.data.Pi1_Description ? this.state.data.Pi1_Description:""}  />
                          </div>
                      </div>
                  </div>
              </div>

            <div className="row">
              <div className="col-xl-3" style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
                <h1>Weekly Unit Sales</h1>
              </div>
              <div className="col-xl-9">
                  <MixedWidget16 weeklysales={this.state.weeklyUnitSales} />
              </div>
              <AccessModal showModal={this.state.show_modal} hidemodal={this.hidemodal} modalerrormessage={this.state.modal_error_message} />
              <PopupMessageHomePage showModalMessage={this.state.show_popup_message}  hidemodalMessage={this.popupMessage} />
          </div>
              {/* end::Row */}

              {/* end::Dashboard */}
          </>
        )
    }

  }

export default Demo2Dashboard;
