import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { EmployeeDashboardPage } from "./pages/EmployeeDashboardPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const AdminUser = lazy(() =>
  import ('./modules/AdminUserList/Userindex')
);
const CompanyProfile = lazy(() =>
import ("./modules/CompanyProfile/CompanyCreate")
)
const AdminProfile = lazy(() =>
import ("./modules/AdminCompanyProfile/Company")
)

const BrokerProfile = lazy(() =>
import ("./modules/AdminCompanyProfile/BrokerCompany")
)

const CompanyPlan = lazy(() =>
  import ("./modules/CompanyPlan/Planindex")
)
const Salesitem  = lazy(() =>
  import ("./modules/Salesbyitem/Salesitem")
)
const Salesbyday  = lazy(() =>
  import ("./modules/Salesbyday/Salesitem")
)
const Categoryrankbrand  = lazy(() =>
  import ("./modules/CategoryRankBrand/Categoryranklist")
)
const CategoryRankItem  = lazy(() =>
  import ("./modules/CategoryRankItem/Categoryrankitem")
)

const WeeklyUnitSales = lazy(() =>
  import ("./modules/WeeklyUnitSales/UnitSalesindex")
)
const WeeklyVelocity = lazy(() =>
  import ("./modules/WeeklyVelocity/WeeklyVelocityindex")
)
const CategorySales = lazy(() =>
  import ("./modules/CategorySales/CategorySalesindex")
)
const ErewhonUser = lazy(() =>
    import ("./modules/ErewhonUserProfile/UserPage")
)
const ErewhonUserchangepassword = lazy(() =>
    import ("./modules/ErewhonUserProfile/ChangePassword")
)
const InventoryManagement = lazy(() =>
    import ("./modules/InventoryManagement/InventoryManagementList")
)
const Contactusindex = lazy(() =>
    import ("./modules/Contactus/Contactusindex")
)

const BasketAnalysis = lazy(() =>
    import('./modules/BasketAnalysis/index')
)

const InventoryLookup = lazy(() =>
    import('./modules/InventoryLookup/index')
)

const Vendors = lazy(() =>
    import('./modules/VendorForms/index')
)

const MessageBoard = lazy(() =>
    import('./modules/MessageBoard/index')
)



let user_type = localStorage.getItem('user_type');

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard.
            <Redirect exact from="/" to="/dashboard" />
          */
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route exact path="/" component={DashboardPage} />
        <Route path="/brand_data/weekly_dollar_sales" component={Salesitem} />
        <Route path="/brand_data/weekly_unit_sales" component={WeeklyUnitSales} />
        <Route path="/brand_data/weekly_velocity" component={WeeklyVelocity} />
        <Route path="/brand_data/sales_by_day" component={Salesbyday} />
        <Route path="/brand_data/inventory_management" component={InventoryManagement} />
        <Route path='/vendor_forms' component={Vendors} />

        <Route path="/category_data/category_brand_rank" component={Categoryrankbrand} />
        <Route path="/category_data/category_item_rank" component={CategoryRankItem} />
        <Route path="/category_data/categorysales" component={CategorySales} />
        <Route path="/user/profile" component={ErewhonUser} />
        <Route path="/user/changepassword" component={ErewhonUserchangepassword} />
        <Route path='/contactus' component= {Contactusindex}/>
        <Route path='/inventory/lookup' component= {InventoryLookup} />
        <Route path='/basket_analysis' component= {BasketAnalysis} />
        <Route path='/message_board' component= {MessageBoard} />

        {
        user_type !== undefined &&  user_type == "admin" &&
          <React.Fragment>
            <Route path="/admin/brands" component={AdminProfile} />
            <Route path="/admin/users" component={AdminUser} />
            <Route path = "/admin/basket_analysis" component={BasketAnalysis} />
          </React.Fragment>
        }
        {
          user_type !== undefined && user_type == "company" &&

              <React.Fragment>
                <Route path='/company/profile' component ={CompanyProfile} />
                <Route path='/company/billing' component= {CompanyPlan}/>
                <Route path = '/broker/brands' component={BrokerProfile} />
              </React.Fragment>
        }
        <Redirect to="/error/error-v2" />
      </Switch>
    </Suspense>
  );
}
