import React,{useState,useEffect} from 'react';
import {Toast} from 'react-bootstrap';
import { Modal } from "react-bootstrap";
import { Redirect,Link,withRouter,hashHistory,useLocation  } from "react-router-dom";
import { ToastContainer,toast } from 'react-toastify';
import axios from 'axios';
import Qs from 'querystring';
import {API_URL,CONFIG_WITH_TOKEN,LIVE_API_URL}  from '../../../appconstants/appconstantsurl';

function PopupMessageHomePage(props){

    const [messageData, setMessageData] = useState([]);

    useEffect(() =>{
        getAllMessage();
    },[])


    const getAllMessage = () => {
        let submit_data = {
            limit:7
        }
        axios.post(API_URL + '/apis/messages',Qs.stringify(submit_data),CONFIG_WITH_TOKEN)
        .then((response) =>{
            if(response.data.error == 0){
                if(response.data.result.length == 0){
                    sessionStorage.setItem("close_popup","yes");
                    props.hidemodalMessage();
                }
                setMessageData(response.data.result);
            }

        }).catch((error) => {
            if(error){
                toast.error(error.message)
            }
        })
    }

    return(
        <div>
          <Modal
            size="md"
            show={props.showModalMessage}
            onHide={props.hidemodalMessage}
            aria-labelledby="example-modal-sizes-title-lg"
            shouldCloseOnOverlayClick={false}
            backdrop="static"
          >
          <Modal.Header>
          <div className ="row" style={{width:"100%",justifyContent:"center"}}>
              <p>Latest Erewhon Messages</p>
            </div>
           </Modal.Header>
          <Modal.Body>
          <div className="row" style={{justifyContent:"center",maxHeight:"500px",overflowY:"scroll",padding:"10px"}}>
            {messageData && messageData !== "" && messageData.map((value, index) => {
                return (
                    <div className="message_list" style={{borderBottom:"1px solid #cccccc", paddingTop:"20px",width:"100%"}}>
                        <div className="title" style={{display:"flex"}}>
                            <p style={{flex:"1"}}><strong>{value.title}</strong></p>
                            <p><strong>{value.updatedAt}</strong></p>
                        </div>
                        <div className="details" style={{marginBottom:"20px"}}>
                            <p>{value.message_details}</p>
                        </div>
                    </div>
                )
            })}

           </div>
          </Modal.Body>
          <Modal.Footer>
          <div className ="row" style={{width:"100%",justifyContent:"center"}}>
              <button
                type="button"
                className="btn mr-2"
                onClick={props.hidemodalMessage}
                style={{backgroundColor:"#2A3030",borderColor:'#2A3030',color:"#ffffff"}}>Close</button>
            </div>
           </Modal.Footer>
          </Modal>
        </div>

    );

}

export default PopupMessageHomePage;
