import React,{useState,useEffect} from 'react';
import {Toast} from 'react-bootstrap';
import { Modal } from "react-bootstrap";
import { Redirect,Link,withRouter,hashHistory,useLocation  } from "react-router-dom";



function AccessModal(props){
    const [redirectto,setRedirectto] = useState("here");
    const [removeupgradebtn,setRemoveupgradebtn] = useState(false);
    let user_type = localStorage.getItem("company_user_type");

    useEffect(()=>{
        if(user_type == "broker"){
            setRemoveupgradebtn(true)
        }
    },[])
    const upgradeplan = () => {

        setRedirectto("billing")
    }
    const redirectdashboard = () => {
        setRedirectto("dashboard")
    }
    if(redirectto == "billing"){
        return <Redirect to="/company/billing" />
    }else if(redirectto == "dashboard"){
        return <Redirect to="/" />
    }else{
        return(
            <div>
              <Modal
                size="md"
                show={props.showModal}
                onHide={props.hidemodal}
                aria-labelledby="example-modal-sizes-title-lg"
                shouldCloseOnOverlayClick={false}
                backdrop="static"
              >
              <Modal.Body>
              <div className="row" style={{justifyContent:"center"}}>
                  <div className="alert-text font-weight-bold">{props.modalerrormessage}</div>
               </div>
              </Modal.Body>
              <Modal.Footer>
              <div className ="row" style={{width:"100%",justifyContent:"center"}}>
              {removeupgradebtn !== true &&
              <button
                type="button"
                onClick={upgradeplan}
                className="btn mr-2"
                style={{backgroundColor:"#2A3030",borderColor:'#2A3030',color:"#ffffff"}}>Upgrade Now</button>
                }
                <button
                  type="button"
                  className="btn mr-2"
                  onClick={redirectdashboard}
                  style={{backgroundColor:"#2A3030",borderColor:'#2A3030',color:"#ffffff"}}>Cancel</button>
                </div>
               </Modal.Footer>
              </Modal>
            </div>

        );
    }


}

export default AccessModal;
