/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React,{useEffect} from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

let user_first_name = localStorage.getItem('user_first_name');
let user_last_name = localStorage.getItem('user_last_name');
let company_name = localStorage.getItem('company_name');
let user_type = localStorage.getItem('user_type');
let company_user_type = localStorage.getItem('company_user_type');
let company_review_status = localStorage.getItem('company_status_review');
let company_status = localStorage.getItem('company_status');
let plan_name = localStorage.getItem('user_plan_name');
let login_type = localStorage.getItem('login_type');
let company_ecrs_type = localStorage.getItem('company_ecrs_type');

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";

    }
    useEffect (() =>{
        login_type = localStorage.getItem('login_type');

    },[login_type])

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            {user_type == "company" && company_status == "active" &&
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                    <NavLink className="menu-link" to="/dashboard">
                        <span className="menu-text">Dashboard</span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                    </NavLink>
                </li>
             }
             {
                 login_type == "employee" && user_type !== "admin" && user_type !== "company" &&
                 <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                     <NavLink className="menu-link" to="/inventory/lookup">
                         <span className="menu-text">Inventory Lookup</span>
                         {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                     </NavLink>
                 </li>

             }
            {user_type == "admin" &&

            <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link" to="/dashboard">
                    <span className="menu-text">Dashboard</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
          }
            {user_type == "company" && company_status == "active" ?
            <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/brand_data')}`}>
                <NavLink className="menu-link menu-toggle" to="/brand_data">
                    <span className="menu-text">Brand Data</span>
                    <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">
                        <li className={`menu-item ${getMenuItemActive('/brand_data/weekly_unit_sales')}`}>
                            <NavLink className="menu-link" to="/brand_data/weekly_unit_sales">
                                <span className="menu-text">Weekly Unit Sales</span>
                                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/brand_data/weekly_dollar_sales')}`}>
                            <NavLink className="menu-link" to="/brand_data/weekly_dollar_sales">

                                <span className="menu-text" style={{opacity:plan_name == "Intro" || plan_name == "" ? "0.5":"1.0"}}>Weekly Dollar Sales</span>
                                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}

                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/brand_data/weekly_velocity')}`}>
                            <NavLink className="menu-link" to="/brand_data/weekly_velocity">
                                <span className="menu-text" style={{opacity:plan_name == "Intro" || plan_name == "" ? "0.5":"1.0"}}>Weekly Velocity</span>
                                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/brand_data/sales_by_day')}`}>
                            <NavLink className="menu-link" to="/brand_data/sales_by_day">
                                <span className="menu-text" style={{opacity:plan_name == "Intro" || plan_name == "" ? "0.5":"1.0"}}>Sales By Day</span>
                                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/brand_data/inventory_management')}`}>
                            <NavLink className="menu-link" to="/brand_data/inventory_management">
                                <span className="menu-text" style={{opacity:plan_name == "Intro" || plan_name == "" ? "0.5":"1.0"}}>Inventory Management</span>
                                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                            </NavLink>
                        </li>

                        {/*end::3 Level*/}

                    </ul>
                </div>
            </li>

          :user_type == "admin" || company_user_type == "broker" || company_user_type == "user" || company_user_type == "admin"  ?
          <li
              data-menu-toggle={layoutProps.menuDesktopToggle}
              aria-haspopup="true"
              className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/brand_data')}`}>
              <NavLink className="menu-link menu-toggle" to="/brand_data">
                  <span className="menu-text">Brand Data</span>
                  <i className="menu-arrow"></i>
              </NavLink>
              <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                  <ul className="menu-subnav">
                      <li className={`menu-item ${getMenuItemActive('/brand_data/weekly_unit_sales')}`}>
                          <NavLink className="menu-link" to="/brand_data/weekly_unit_sales">
                              <span className="menu-text">Weekly Unit Sales</span>
                              {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                          </NavLink>
                      </li>
                      <li className={`menu-item ${getMenuItemActive('/brand_data/weekly_dollar_sales')}`}>
                          <NavLink className="menu-link" to="/brand_data/weekly_dollar_sales">
                              <span className="menu-text" style={{opacity: user_type == "broker" || user_type == "user" && plan_name == "" ? "0.5":"1.0"}}>Weekly Dollar Sales</span>
                              {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}

                          </NavLink>
                      </li>
                      <li className={`menu-item ${getMenuItemActive('/brand_data/weekly_velocity')}`}>
                          <NavLink className="menu-link" to="/brand_data/weekly_velocity">
                              <span className="menu-text" style={{opacity: user_type == "broker" || user_type == "user" && plan_name == "" ? "0.5":"1.0"}}>Weekly Velocity</span>
                              {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                          </NavLink>
                      </li>
                      <li className={`menu-item ${getMenuItemActive('/brand_data/sales_by_day')}`}>
                          <NavLink className="menu-link" to="/brand_data/sales_by_day">
                              <span className="menu-text" style={{opacity: user_type == "broker" || user_type == "user" && plan_name == "" ? "0.5":"1.0"}}>Sales By Day</span>
                              {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                          </NavLink>
                      </li>
                      <li className={`menu-item ${getMenuItemActive('/brand_data/inventory_management')}`}>
                          <NavLink className="menu-link" to="/brand_data/inventory_management">
                              <span className="menu-text" style={{opacity: user_type == "broker" || user_type == "user" && plan_name == "" ? "0.5":"1.0"}}>Inventory Management</span>
                              {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                          </NavLink>
                      </li>
                      {/*end::3 Level*/}

                  </ul>
              </div>
          </li>

          :""}



        {user_type == "company" && company_status == "active" ?
        <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/category_data/')}`}>
            <NavLink className="menu-link menu-toggle" to="/category_data/">
                <span className="menu-text" style={{opacity:plan_name == "Starter" || plan_name == "" ? "0.5":"1.0"}}>Category Data</span>
                <i className="menu-arrow"></i>
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                <ul className="menu-subnav">
                    {/*begin::2 Level*/}
                    <li className={`menu-item ${getMenuItemActive('/category_data/category_brand_rank')}`}>
                        <NavLink className="menu-link" to="/category_data/category_brand_rank">
                            <span className="menu-text" style={{opacity:plan_name == "Starter" || plan_name == ""  ? "0.5":"1.0"}}>Category Brand Rank</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>
                    <li className={`menu-item ${getMenuItemActive('/category_data/category_item_rank')}`}>
                        <NavLink className="menu-link" to="/category_data/category_item_rank">
                            <span className="menu-text" style={{opacity:plan_name == "Starter"  || plan_name == "" ? "0.5":"1.0"}}>Category Item Rank</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>

                    {/*end::3 Level*/}

                </ul>
            </div>
        </li>

        : user_type == "admin" || company_user_type == "broker" || company_user_type == "user" || company_user_type == "admin"  ?    <li
              data-menu-toggle={layoutProps.menuDesktopToggle}
              aria-haspopup="true"
              className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/category_data/')}`}>
              <NavLink className="menu-link menu-toggle" to="/category_data/">
                  <span className="menu-text" style={{opacity: user_type == "broker" || user_type == "user" && plan_name == "Starter" || plan_name == "" ? "0.5":"1.0"}}>Category Data</span>
                  <i className="menu-arrow"></i>
              </NavLink>
              <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                  <ul className="menu-subnav">
                      {/*begin::2 Level*/}
                      <li className={`menu-item ${getMenuItemActive('/category_data/category_brand_rank')}`}>
                          <NavLink className="menu-link" to="/category_data/category_brand_rank">
                              <span className="menu-text" style={{opacity: user_type == "broker" || user_type == "user" && plan_name == "Starter" || plan_name == "" ? "0.5":"1.0"}}>Category Brand Rank</span>
                              {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                          </NavLink>
                      </li>
                      <li className={`menu-item ${getMenuItemActive('/category_data/category_item_rank')}`}>
                          <NavLink className="menu-link" to="/category_data/category_item_rank">
                              <span className="menu-text" style={{opacity: user_type == "broker" || user_type == "user" && plan_name == "Starter" || plan_name == "" ? "0.5":"1.0"}}>Category Item Rank</span>
                              {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                          </NavLink>
                      </li>

                      {/*end::3 Level*/}

                  </ul>
              </div>
          </li> :"" }
          {
               company_ecrs_type == "grocery" &&
                  <li className={`menu-item menu-item-rel ${getMenuItemActive('/basket_analysis')}`}>
                      <NavLink className="menu-link" to="/basket_analysis">
                          <span className="menu-text" style={{opacity: plan_name == "Starter" || plan_name == "" ? "0.5":"1.0"}}>Basket Analysis</span>
                          {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                      </NavLink>
                  </li>

          }
          {
              <li className={`menu-item menu-item-rel ${getMenuItemActive('/vendor_forms')}`}>
                  <NavLink className="menu-link" to="/vendor_forms">
                      <span className="menu-text">Vendor Forms</span>
                      {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                  </NavLink>
              </li>
          }

          <li className={`menu-item menu-item-rel ${getMenuItemActive('/message_board')}`}>
              <NavLink className="menu-link" to="/message_board">
                  <span className="menu-text">Message Board</span>
                  {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
              </NavLink>
          </li>

            {/*end::1 Level*/}
        </ul>
        {/*end::Header Nav*/}
    </div>;
}
