import axios from "axios";
import Qs from 'querystring';

import {API_URL}  from '../../../../appconstants/appconstantsurl';

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = API_URL + '/apis/register';
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";


const configs = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
}

export const ME_URL = "api/me";

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(values) {
  let register_data = {
    email:values.email,
    first_name:values.firstname,
    last_name:values.lastname,
    password:values.password,
  }
  return axios.post(REGISTER_URL, Qs.stringify(register_data),configs);
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
