/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import {ArrowDown, ArrowUp} from "react-bootstrap-icons";

export function TilesWidget11({
  className,
  baseColor = "primary",
  widgetHeight = "150px",
  title,
  amount,up_down,brand_name,
  percent
}) {
  return (
    <>
      <div
        className={`card card-custom bg-${baseColor} ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className="card-body">
          <div
            className={`text-inverse-${baseColor} font-weight-bolder font-size-h2 mt-3`}
            style={{fontSize:"28px"}}
          >
            {title}
          </div>
          <div style={{display:"flex"}}>
            <div style={{width:"65%"}}>
            <span
              className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1`}
              style={{fontSize:"36px"}}>

              {amount}
            </span>

            </div>
            <div style={{marginTop:"15px"}}>
            {brand_name == undefined &&
              <span style={{fontSize: "22px",color: `${up_down == "up" ? "green":"red"}`}}>
              {up_down == "up" &&
                <ArrowUp color="green" />
              }
              {up_down == "down" &&
                <ArrowDown color="red" />
              }
              {percent}

              </span>
              }
              {brand_name !== undefined &&
                  <span style={{fontSize: "12px",color:"#ffffff"}}>{brand_name}</span>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
