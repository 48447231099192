import React,{useState,useEffect} from 'react';
import { ToastContainer } from 'react-toastify';

class ReactError extends React.Component{
  render(){

    return(
        <div>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
        </div>

    );
  }

}

export default ReactError;
