/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import {custom_ecrs_id} from '../../../../../appconstants/appconstantsurl';
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";

export function UserProfileDropdown() {
  //const { user } = useSelector((state) => state.auth);
 const dispatch = useDispatch();

 const ECRS_ID = useSelector((state) => state.auth.redux_ecrs_id, shallowEqual);

 let user_first_name = localStorage.getItem('user_first_name');
 let user_last_name = localStorage.getItem('user_last_name');
 let company_name = localStorage.getItem('company_name');
 let company_ecrs_name = localStorage.getItem('company_ecrs_name');
 let user_type = localStorage.getItem('user_type');
 let company_user_type = localStorage.getItem('company_user_type');
 let company_review_status = localStorage.getItem('company_status_review');
 let company_status = localStorage.getItem('company_status');
 let companies_count = localStorage.getItem('companies_count');
 let login_type = localStorage.getItem('login_type');

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);
const logoutClick = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href='/auth/login';
}

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
          }
        >
          <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>
          <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-1" style={{textTransform:'capitalize'}}>
            {
              user_first_name !== "" && user_first_name !== undefined  && user_first_name !== null &&
                user_first_name ? user_first_name +" " : ""
            }
            {
              user_last_name !== "" && user_last_name !== undefined  && user_last_name !== null &&
                user_last_name ? user_last_name:""
            }

            {login_type == "employee" &&
                "admin"
            }

            </span>
            <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline">
            {
               company_ecrs_name !== "" && company_ecrs_name !== undefined && company_ecrs_name !== null &&
               company_ecrs_name  ?
               "[" + company_ecrs_name + "]" : ""
            }
          </span>

          <span className="burger-icon burger-icon-center" style={{right:"12px"}}><span className="symbol-label text-white  font-size-h5 font-weight-bold bg-white-o-30 d-sm-block d-md-none"></span></span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-3 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5" style={{textTransform:'capitalize'}}>
                {
                  user_first_name !== "" && user_first_name !== undefined  &&
                    user_first_name ? user_first_name +" " :""
                }
                {
                  user_last_name !== "" && user_last_name !== undefined  &&
                    user_last_name ? user_last_name:""
                }
                {
                   company_ecrs_name !== "" && company_ecrs_name !== undefined && company_ecrs_name !== null &&
                   company_ecrs_name  ?
                   "[" + company_ecrs_name + "]" : ""
                }
                </div>
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/misc/bg-1.jpg"
                )})`,
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-success font-weight-bold font-size-h4">
                </span>
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5" style={{textTransform:'capitalize'}}>
              {
                user_first_name !== "" && user_first_name !== undefined  &&
                  user_first_name ? user_first_name +" " :""
              }
              </div>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
        {login_type !== "employee" &&
          <Link to="/user/profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon-profile-1" style={{color:"#000000"}} />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  My Profile
                </div>
                <div className="text-muted">

                </div>
              </div>
            </div>
          </Link>
          }

          {user_type == "company" && company_user_type == "admin" && company_review_status !== "review" &&
              <Link to="/company/billing" className="navi-item px-8 cursor-pointer">
                <div className="navi-link">
                  <div className="navi-icon mr-2">
                    <i className="fab fa-cc-stripe" style={{color:"#000000"}} />
                  </div>
                  <div className="navi-text">
                    <div className="font-weight-bold cursor-pointer">
                        Company Billing
                    </div>
                    <div className="text-muted">

                    </div>
                  </div>
                </div>
              </Link>
          }
          {
              user_type == "company" && company_user_type == "admin" &&
              <Link to="/company/profile" className="navi-item px-8 cursor-pointer">
                <div className="navi-link">
                  <div className="navi-icon mr-2">
                    <i className="far fa-building" style={{color:"#000000"}} />
                  </div>
                  <div className="navi-text">
                    <div className="font-weight-bold cursor-pointer">
                      Brand Profile
                    </div>
                    <div className="text-muted">

                    </div>
                  </div>
                </div>
              </Link>
          }

          {
              user_type == "company" && company_user_type !== "" && company_user_type !== null && company_user_type !== undefined &&
              <Link to="/broker/brands" className="navi-item px-8 cursor-pointer">
                <div className="navi-link">
                  <div className="navi-icon mr-2">
                    <i className="far fa-building" style={{color:"#000000"}} />
                  </div>
                  <div className="navi-text">
                    <div className="font-weight-bold cursor-pointer">
                      Assigned Brands
                    </div>
                    <div className="text-muted">

                    </div>
                  </div>
                </div>
              </Link>
          }
          {
              user_type == "company" &&
              <Link to="/contactus" className="navi-item px-8 cursor-pointer">
                <div className="navi-link">
                  <div className="navi-icon mr-2">
                    <i className="far fa-envelope" style={{color:"#000000"}} />
                  </div>
                  <div className="navi-text">
                    <div className="font-weight-bold cursor-pointer">
                      Contact Us
                    </div>
                    <div className="text-muted">

                    </div>
                  </div>
                </div>
              </Link>
          }
          {
            user_type == "admin" &&
            <Link to="/admin/brands" className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon-business" style={{color:"#000000"}} />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">
                    All Brands
                  </div>
                  <div className="text-muted">

                  </div>
                </div>
              </div>
            </Link>
          }
          { user_type == "admin" &&
            <>
            <Link to="/admin/users" className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon-users" style={{color:"#000000"}} />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">
                  All Users
                  </div>
                  <div className="text-muted">

                  </div>
                </div>
              </div>
            </Link>
            <Link to="/admin/basket_analysis" className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon2-analytics-1" style={{color:"#000000"}} />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">
                  Basket Analysis
                  </div>
                  <div className="text-muted">

                  </div>
                </div>
              </div>
            </Link>

            </>
          }
          <div className="navi-separator mt-3"></div>

          <div className="navi-footer  px-8 py-5">
            <Link
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
              onClick={logoutClick}
              style={{backgroundColor:"#2a3030",color:"#ffffff"}}
            >
              Sign Out
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
