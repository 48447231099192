import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { Modal } from "react-bootstrap";
import axios from 'axios';
import Qs from 'querystring';
import OtpInput from 'react-otp-input';


import {API_URL, CONFIG_WITHOUT_TOKEN_BRAND}  from '../../../../appconstants/appconstantsurl';

const REGISTER_URL = API_URL + '/apis/register';

const REGISTER_OTP_URL = API_URL + '/apis/otp';


const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  changepassword: "",
  phone:"",
  acceptTerms: false,
};

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [showotp, setShowotp] = useState(false);
  const [otploading, setOtploading] = useState(false);
  const [otpcode, setOtpcode] = useState("");
  const [emailtoken, setEmailtoken] = useState("");
  const [otperror, setOtperror] = useState("");

  const RegistrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(3, "Minimum 3 word is required")
      .max(50, "Maximum 50 word is required")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    lastname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    phone: Yup.string()
          .required("Phone number is required")
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(10, 'Must be exactly 10 digits')
          .max(10, 'Must be exactly 10 digits'),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One special case Character"
        )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });
  const hideotp = () => {
      setShowotp(false);
  }
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const codeisenter = (otp) => {
      setOtpcode(otp);
      if(otp.length >= 4){
          handleSubmitcode(otp);
      }

  }
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const handleSubmitcode = (otp) => {
      setOtploading(true)
      if(otp !== ""){
          let otp_data = {
              email_code:otp,
              email_verify:"no"
          }
          const CONFIG_WITH_TOKEN_OTP = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + emailtoken,
            }
          }
          axios.post(REGISTER_OTP_URL, Qs.stringify(otp_data),CONFIG_WITH_TOKEN_OTP)
              .then(function (response) {
                  if(response.data.error == 0){
                      setEmailtoken("");
                      let token  = response.data.token;
                      let user_type = response.data.user.type;
                      localStorage.setItem('token',token);
                      localStorage.setItem('user_type',user_type);
                      localStorage.setItem('user_first_name',response.data.user.first_name);
                      localStorage.setItem('user_last_name',response.data.user.last_name);
                      window.location.href = '/company/profile';
                  }else if(response.data.error == 1){
                      setShowotp(true);
                      setOtploading(false);
                      setOtperror(response.data.message);
                  }
              })
              .catch(function (error) {
                  setShowotp(true);
                  setOtploading(false);
                  setOtperror(error.message);
              });
      }else{
          if(otp == ""){
              setOtploading(false);
              setOtperror("Please Enter a Code");

          }
      }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      let register_data = {
        email:values.email,
        first_name:values.firstname,
        last_name:values.lastname,
        password:values.password,
        phone:values.phone,
      }
      axios.post(REGISTER_URL, Qs.stringify(register_data),CONFIG_WITHOUT_TOKEN_BRAND)
          .then(function (response) {
            disableLoading();
            setSubmitting(false);
            if(response.data.error == 0){
              setShowotp(true)
              let token  = response.data.token;
              setEmailtoken(token);
            }else if(response.data.error == 1){
              setSubmitting(false);
              setStatus(response.data.message);
              disableLoading();
            }

          })
          .catch(function (error) {
              setSubmitting(false);
              setStatus(error.message);
              disableLoading();
          });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      {showotp == false  &&
          <div>

          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.REGISTER.TITLE" />
            </h3>
            <p className="text-muted font-weight-bold">
            </p>
          </div>
              <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                onSubmit={formik.handleSubmit}
              >
                {/* begin: Alert */}
                {formik.status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                  </div>
                )}
                {/* end: Alert */}

                {/* begin: firstname */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="First Name"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "firstname"
                    )}`}
                    name="firstname"
                    {...formik.getFieldProps("firstname")}
                  />
                  {formik.touched.firstname && formik.errors.firstname ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block" style={{color:"red"}}>{formik.errors.firstname}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: firstname */}

                {/* begin: lastname */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Last Name"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "lastname"
                    )}`}
                    name="firstname"
                    {...formik.getFieldProps("lastname")}
                  />
                  {formik.touched.lastname && formik.errors.lastname ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block" style={{color:"red"}}>{formik.errors.lastname}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: lastname */}

                {/* begin: Email */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Email"
                    type="email"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "email"
                    )}`}
                    name="email"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block" style={{color:"red"}}>{formik.errors.email}</div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                    <input
                      type="text"
                      placeholder="Phone"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "phone"
                      )}`}
                      name="phone"
                      {...formik.getFieldProps("phone")}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block" style={{color:"red"}}>{formik.errors.phone}</div>
                      </div>
                    ) : null}
                </div>
                {/* end: Email */}

                {/* begin: Password */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "password"
                    )}`}
                    name="password"
                    {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block" style={{color:"red"}}>{formik.errors.password}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: Password */}

                {/* begin: Confirm Password */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Confirm Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "changepassword"
                    )}`}
                    name="changepassword"
                    {...formik.getFieldProps("changepassword")}
                  />
                  {formik.touched.changepassword && formik.errors.changepassword ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block" style={{color:"red"}}>
                        {formik.errors.changepassword}
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* end: Confirm Password */}

                {/* begin: Terms and Conditions */}
                <div className="form-group">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="acceptTerms"
                      className="m-1"
                      {...formik.getFieldProps("acceptTerms")}
                    />

                      <label className="mr-1" style={{marginTop:"6px"}}>
                      I agree to the Erewhon <a href="https://erewhonmarket.com/terms-and-conditions/" target="_blank"> Terms & Conditions</a>
                      </label>
                    <span />
                  </label>
                  {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block" style={{color:"red"}}>{formik.errors.acceptTerms}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: Terms and Conditions */}
                <div className="form-group d-flex flex-wrap flex-center">
                  <button
                    type="submit"
                    disabled={
                      formik.isSubmitting ||
                      !formik.isValid ||
                      !formik.values.acceptTerms
                    }
                    style={{backgroundColor:"#2A3030",borderColor:'#2A3030'}}
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    <span>Continue</span>
                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                  </button>

                  <Link to="/auth/login">
                    <button
                      type="button"
                      className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
              </form>
          </div>
        }
      <div>
      {showotp == true  &&
          <div>
          {otperror !== "" &&
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible" style={{backgroundColor:"#000000"}}>
                <div className="alert-text font-weight-bold" style={{color:"#ffffff"}}>{otperror}</div>
              </div>
          }

          <div>
          <div className="row">
            <label>Please enter verification code sent to your email</label>
                {otploading && <span className="ml-3 spinner spinner-black"></span>}
          </div>
            <div style={{marginTop:"15px"}}>
              <OtpInput
                  value={otpcode}
                  onChange={codeisenter}
                  numInputs={4}
                  separator={<span>-</span>}
                  inputStyle={{width:"2em",height:"2em",fontSize:"30px"}}
                  shouldAutoFocus={true}
                />
            </div>
          </div>
          </div>

        }
        </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
