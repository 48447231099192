import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { Modal } from "react-bootstrap";
import { login } from "../_redux/authCrud";
import {useSubheader} from "../../../../_metronic/layout";
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import ReactError from '../../ErrorMessage/ReactError';



import axios from 'axios';
import Qs from 'querystring';

import {API_URL,CONFIG_WITHOUT_TOKEN_BRAND,custom_ecrs_id,updateEcrsName}  from '../../../../appconstants/appconstantsurl';

const REGISTER_LOGIN_URL = API_URL + '/apis/login';

const REGISTER_OTP_URL = API_URL + '/apis/otp';

const EMPLOYEE_LOGIN = API_URL + '/apis/email';



/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function EmployeeLogin(props) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Login");
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [smstoken, setSmstoken] = useState("");
  const [showPasscode, setShowPasscode] = useState(false);




  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),

  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }



    return "";
  };

const codeisenter = (otp) => {
    if(otp.length >= 4){
        //handleSubmitcode(otp);
    }

}
  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
    console.log(values);
      enableLoading();
      let login_data;
      let redirct_to_landing = false;
      if(showPasscode == false){
         login_data = {
             email:values.email.trim() + "@enfmkt.com",
         }
         redirct_to_landing = false;
      }else{
          login_data = {
            email:values.email.trim() + "@enfmkt.com",
            password: values.password
          }
          redirct_to_landing = true;
      }
      setTimeout(() => {

        axios.post(EMPLOYEE_LOGIN, Qs.stringify(login_data),CONFIG_WITHOUT_TOKEN_BRAND)
            .then(function (response) {
              disableLoading();
              setSubmitting(false);
              if(response.data.error == 0){
                  setShowPasscode(true);
                  toast.dark(response.data.message);
                  if(redirct_to_landing  == true){
                      localStorage.setItem("token",response.data.token);
                      localStorage.setItem("login_type","employee");
                      window.location.href = '/employee';
                  }

              }else{
                  toast.dark(response.data.message);
              }

            })
            .catch((error) =>{
                if(error.response){
                    toast(error.response.data.message);
                }else{
                    toast(error.message);
                }
            });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
    <div>
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          Employee Login
        </h3>
        <p className="text-muted font-weight-bold">
          Enter email and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}

        <div className="input-group mb-3 fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="text"
            autocapitalize="none"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            style={{textTransform:"lowercase"}}
            {...formik.getFieldProps("email")}
          />
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2">@enfmkt.com</span>
          </div>
        </div>
        {formik.touched.email && formik.errors.email ? (
          <div className="fv-plugins-message-container mb-2">
            <div className="fv-help-block" style={{color:'#F64E60'}}>{formik.errors.email}</div>
          </div>
        ) : null}
        {showPasscode == true &&
            <>
                <div className="input-group fv-plugins-icon-container">
                  <input
                    placeholder="Password"
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "password"
                    )}`}
                    name="password"
                    {...formik.getFieldProps("password")}
                  />
                </div>
            </>
        }
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            style={{backgroundColor:"#2A3030",borderColor:'#2A3030'}}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}

          >
            <span>{showPasscode == true ? "Submit" :"Send Password" }</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
        <ReactError />
      </form>
      {/*end::Form*/}
    </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(EmployeeLogin));
