import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { Modal } from "react-bootstrap";
import { login } from "../_redux/authCrud";
import {useSubheader} from "../../../../_metronic/layout";
import OtpInput from 'react-otp-input';
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";




import axios from 'axios';
import Qs from 'querystring';

import {API_URL,CONFIG_WITHOUT_TOKEN_BRAND,custom_ecrs_id,updateEcrsName}  from '../../../../appconstants/appconstantsurl';

const REGISTER_LOGIN_URL = API_URL + '/apis/login';

const REGISTER_OTP_URL = API_URL + '/apis/otp';



/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Login");
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [showotp, setShowotp] = useState(false);
  const [otpcode, setOtpcode] = useState("");
  const [otploading, setOtploading] = useState(false);
  const [otperror, setOtperror] = useState("");
  const [smstoken, setSmstoken] = useState("");
  const [emailverify, setEmailverify] = useState(false);

  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
const hideotp = () => {
    setShowotp(false);
}
const codeisenter = (otp) => {
    setOtpcode(otp);
    if(otp.length >= 4){
        handleSubmitcode(otp);
    }

}
const handleSubmitcode = (otp) => {
    setOtploading(true)
    if(otp !== ""){
        let otp_data = {
            otp:otp
        }
        const CONFIG_WITH_TOKEN_OTP = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': 'Bearer ' + smstoken,
          }
        }
        axios.post(REGISTER_OTP_URL, Qs.stringify(otp_data),CONFIG_WITH_TOKEN_OTP)
            .then(function (response) {
                if(response.data.error == 0){
                    let token  = response.data.token;
                    let user_type = response.data.user.type;
                    let user_company_details;
                    let user_company_stripe_details;
                    localStorage.setItem('token',token);
                    localStorage.setItem('user_type',user_type);
                    localStorage.setItem('user_first_name',response.data.user.first_name);
                    localStorage.setItem('user_last_name',response.data.user.last_name);
                    localStorage.setItem('companies_count',response.data.user.companies_count);
                    if(response.data.user.companies_count == 1){
                        localStorage.setItem('token',response.data.ecrs_token);
                        localStorage.setItem('company_user_type',response.data.user.company_users[0].user_type);
                            if(response.data.user.company_users[0].company.stripe_subscription !== null){
                                    localStorage.setItem('user_plan_name',response.data.user.company_users[0].company.stripe_subscription.plan_price.plan.name);
                                    user_company_stripe_details = response.data.user.company_users[0].company.stripe_subscription;

                            }
                            user_company_details = response.data.user.company_users[0];
                            localStorage.setItem('company_name',response.data.user.company_users[0].company.name);
                            localStorage.setItem('company_ecrs_name',response.data.user.company_users[0].company.ECRS_name !== null && response.data.user.company_users[0].company.ECRS_name !== undefined ? response.data.user.company_users[0].company.ECRS_name:"");
                            localStorage.setItem('company_ecrs_type',response.data.user.company_users[0].company.ECRS_type !== null && response.data.user.company_users[0].company.ECRS_type !== undefined ? response.data.user.company_users[0].company.ECRS_type:"");
                            localStorage.setItem('company_status_review',user_company_details.company.status_review);
                            localStorage.setItem('company_status',user_company_details.company.status);
                            if(user_type == "company" && user_company_details == null){
                                window.location.href = '/company/profile';
                            }else if(user_type == "company" && user_company_details !== null && user_company_details.company.status_review == "review"){
                                  window.location.href = '/company/profile';
                            }else if(user_type == "company" && user_company_details !== null && user_company_details.company.status_review == "approved" && user_company_stripe_details == null){
                                  window.location.href = '/company/billing';
                            }else if(user_type == "company" && user_company_details !== null && user_company_details.company.status_review == "declined"){
                              window.location.href = '/company/profile';
                          }else{
                              if(user_type == "company"){
                                  let ecrs_id = response.data.user.company_users[0].company.ECRS_id !== undefined && response.data.user.company_users[0].company.ECRS_id !== "" ? response.data.user.company_users[0].company.ECRS_id:"";
                                  dispatch(props.setEcrs(ecrs_id));

                              }
                             window.location.href = '/dashboard';
                          }

                        }else if(response.data.user.companies_count == 0){
                            if(user_type == "company"){
                                window.location.href = '/company/profile';
                            }else if(user_type == "admin"){
                                window.location.href = '/admin/brands';
                            }
                        }else if(response.data.user.companies_count >= 1){
                            if(user_type == "company"){
                                window.location.href = '/broker/brands';
                            }
                        }

                }else if(response.data.error == 1){
                    setShowotp(true);
                    setOtploading(false);
                    setOtperror(response.data.message);
                }
            })
            .catch(function (error) {
                setShowotp(true);
                setOtploading(false);
                setOtperror(error.message);
                console.log(error);
            });
    }else{
        if(otp == ""){
            setOtploading(false);
            setOtperror("Please Enter a Code");

        }
    }
}
  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      let login_data = {
        email:values.email,
        password:values.password
      }
      setTimeout(() => {
        axios.post(REGISTER_LOGIN_URL, Qs.stringify(login_data),CONFIG_WITHOUT_TOKEN_BRAND)
            .then(function (response) {
              disableLoading();
              setSubmitting(false);
              setOtperror("")
              if(response.data.error == 0){
                let temp_token =  response.data.sms_token;
                if(temp_token !== undefined){
                        setSmstoken(temp_token);
                        setShowotp(true);
                }else{
                    let token  = response.data.token;
                    let user_type = response.data.user.type;
                    let user_company_details;
                    let user_company_stripe_details
                    localStorage.setItem('token',token);
                    localStorage.setItem('user_type',user_type);
                    localStorage.setItem('user_first_name',response.data.user.first_name);
                    localStorage.setItem('user_last_name',response.data.user.last_name);

                    if(response.data.user.companies_count == 1){
                        localStorage.setItem('token',response.data.ecrs_token);
                        localStorage.setItem('company_user_type',response.data.user.company_users[0].user_type);
                            if(response.data.user.company_users[0].company.stripe_subscription !== null){
                                    localStorage.setItem('user_plan_name',response.data.user.company_users[0].company.stripe_subscription.plan_price.plan.name);
                                    user_company_stripe_details = response.data.user.company_users[0].company.stripe_subscription;

                            }
                            user_company_details = response.data.user.company_users[0];
                            localStorage.setItem('company_name',response.data.user.company_users[0].company.name);
                            localStorage.setItem('company_ecrs_name',response.data.user.company_users[0].company.ECRS_name !== null && response.data.user.company_users[0].company.ECRS_name !== undefined ? response.data.user.company_users[0].company.ECRS_name:"");
                            localStorage.setItem('company_ecrs_type',response.data.user.company_users[0].company.ECRS_type !== null && response.data.user.company_users[0].company.ECRS_type !== undefined ? response.data.user.company_users[0].company.ECRS_type:"");
                            localStorage.setItem('company_status_review',user_company_details.company.status_review);
                            localStorage.setItem('company_status',user_company_details.company.status);
                            if(user_type == "company" && user_company_details == null){
                                window.location.href = '/company/profile';
                            }else if(user_type == "company" && user_company_details !== null && user_company_details.company.status_review == "review"){
                                  window.location.href = '/company/profile';
                            }else if(user_type == "company" && user_company_details !== null && user_company_details.company.status_review == "approved" && user_company_stripe_details == null){
                                  window.location.href = '/company/billing';
                            }else if(user_type == "company" && user_company_details !== null && user_company_details.company.status_review == "declined"){
                              window.location.href = '/company/profile';
                          }else{
                              if(user_type == "company"){
                                  let ecrs_id = response.data.user.company_users[0].company.ECRS_id !== undefined && response.data.user.company_users[0].company.ECRS_id !== "" ? response.data.user.company_users[0].company.ECRS_id:"";
                                  dispatch(props.setEcrs(ecrs_id));

                              }
                              window.location.href = '/dashboard';
                          }

                        }else if(response.data.user.companies_count == 0){
                            if(user_type == "company"){
                                window.location.href = '/company/profile';
                            }else if(user_type == "admin"){
                                window.location.href = '/admin/brands';
                            }
                        }else if(response.data.user.companies_count >= 1){
                            if(user_type == "company"){
                                window.location.href = '/broker/brands';
                            }
                        }

                    }


              }else if(response.data.error == 1){
                  if(response.data.email_verified == "no"){
                      setEmailverify(true);
                  }
                  setSubmitting(false);
                  disableLoading();
                  setStatus(response.data.message);
              }

            })
            .catch(function (error) {
              //console.log(error);
              setSubmitting(false);
              setStatus(error.message);
              disableLoading();
            });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
        {showotp == false &&
        <div>
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter email and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block" style={{color:'#F64E60'}}>{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block" style={{color:'#F64E60'}}>{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          {emailverify == true &&
          <Link
            to="/auth/emailverification"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
          Verify Email
          </Link>
          }
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            style={{backgroundColor:"#2A3030",borderColor:'#2A3030'}}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}

          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
    }
        {showotp == true &&
            <div>
            {otperror !== "" &&
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible" style={{backgroundColor:"#000000"}}>
                  <div className="alert-text font-weight-bold" style={{color:"#ffffff"}}>{otperror}</div>
                </div>
            }
                <div className="row">
                  <label>Please enter text verification code sent to your phone</label>
                  {otploading && <span className="ml-3 spinner spinner-black"></span>}
                </div>
                <div style={{marginTop:"15px"}}>
                    <OtpInput
                        value={otpcode}
                        onChange={codeisenter}
                        numInputs={4}
                        separator={<span>-</span>}
                        inputStyle={{width:"2em",height:"2em",fontSize:"30px"}}
                        shouldAutoFocus={true}
                      />
                </div>

            </div>
        }
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
