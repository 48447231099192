import React,{useEffect,useState} from "react";
import {
  Dashboard,EmployeeDashboard
} from "../../_metronic/_partials";
import {Redirect} from "react-router-dom";
import {CHECK_USER_TYPE_AND_STATUS,updateEcrsName,custom_ecrs_id} from '../../appconstants/appconstantsurl';
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";


export function DashboardPage() {
const [profilereview,setProfilereview] = useState("company");
const [loginType, setLoginType] = useState("company");

 useEffect(() => {
   let selected_ecrs_id = localStorage.getItem('c_e_i');
   let login_type = localStorage.getItem('login_type');
    if(selected_ecrs_id !== undefined && selected_ecrs_id !== null && selected_ecrs_id !== ""){
        updateEcrsName(selected_ecrs_id);
        localStorage.removeItem("c_e_i");
    }
    setLoginType(login_type);
   let check_user = CHECK_USER_TYPE_AND_STATUS();


   setProfilereview(check_user)

 },[profilereview]);

 if(profilereview == "redirecttoprofile"){
   return <Redirect to="/company/profile" />
 }else if(profilereview == "redirecttobrandlist"){
    return <Redirect to="/admin/brands" />
}else{
    return <Dashboard />;
}

}
