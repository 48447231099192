import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import OtpInput from 'react-otp-input';


import axios from 'axios';
import Qs from 'querystring';

import {API_URL,CONFIG_WITHOUT_TOKEN_BRAND}  from '../../../../appconstants/appconstantsurl';

const REGISTER_EMAIL_URL = API_URL + '/apis/email/verify';

const REGISTER_OTP_URL = API_URL + '/apis/otp';

const initialValues = {
  email: "",
};

function Emailverify(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [emailtoken, setEmailtoken] = useState(false);
  const [otpcode, setOtpcode] = useState("");
  const [otploading, setOtploading] = useState(false);
  const [otperror, setOtperror] = useState("");
  const [showotp, setShowotp] = useState(false);
  const [error, setError] = useState("");


  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const codeisenter = (otp) => {
      setOtpcode(otp);
      if(otp.length >= 4){
          handleSubmitcode(otp);
      }

  }
  const handleSubmitcode = (otp) => {
      setError("");
      setOtploading(true)
      if(otp !== ""){
          let otp_data = {
              email_code:otp
          }
          const CONFIG_WITH_TOKEN_OTP = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + emailtoken,
            }
          }
          axios.post(REGISTER_OTP_URL, Qs.stringify(otp_data),CONFIG_WITH_TOKEN_OTP)
              .then(function (response) {
                  if(response.data.error == 0){
                    setEmailtoken("");
                    setError(response.data.message);
                    setOtploading(false);
                    setTimeout(()=>{
                        window.location.href = '/auth/login';
                    },
                    1000)
                  }else if(response.data.error == 1){
                      setShowotp(true);
                      setOtploading(false);
                      setOtperror(response.data.message);
                  }
              })
              .catch(function (error) {
                  setShowotp(true);
                  setOtploading(false);
                  setOtperror(error.message);
              });
      }else{
          if(otp == ""){
              setOtploading(false);
              setOtperror("Please Enter a Code");

          }
      }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let logout_data = {
          email:values.email
      }
      setTimeout(() => {
          setError("");
      axios.post(REGISTER_EMAIL_URL, Qs.stringify(logout_data),CONFIG_WITHOUT_TOKEN_BRAND)
          .then(function (response) {
            setSubmitting(false);
            if(response.data.error == 0){
                setEmailtoken(response.data.token);
                setShowotp(true);


            }else if(response.data.error == 1){
                setError(response.data.message);
            }
          })
          .catch(function (error) {
            setSubmitting(false);
            setError(error.message);

          });
    }, 1000);
  }
  });

  return (
    <>
        <div>

        {error !== "" &&
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible" style={{backgroundColor:"#000000"}}>
            <div className="alert-text font-weight-bold" style={{color:"#ffffff"}}>
              {error}
            </div>
          </div>
        }

        {showotp == false &&
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Verify Email</h3>
            <div className="text-muted font-weight-bold">
              Enter your email to Verify
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block" style={{color:'#F64E60'}}>{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                style={{backgroundColor:'#2A3030',borderColor:'#2A3030'}}
                disabled={formik.isSubmitting}
              >
                Submit
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
          </div>
          }
          <div className="login-form login-forgot" style={{ display: "block" }}>
          {showotp == true &&
              <div>
              {otperror !== "" &&
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible" style={{backgroundColor:"#000000"}}>
                    <div className="alert-text font-weight-bold" style={{color:"#ffffff"}}>{otperror}</div>
                  </div>
              }
                  <div className="row">
                    <label>Please enter verification code sent to your email</label>
                    {otploading && <span className="ml-3 spinner spinner-black"></span>}
                  </div>
                  <div style={{marginTop:"15px"}}>
                      <OtpInput
                          value={otpcode}
                          onChange={codeisenter}
                          numInputs={4}
                          separator={<span>-</span>}
                          inputStyle={{width:"2em",height:"2em",fontSize:"30px"}}
                          shouldAutoFocus={true}
                        />
                  </div>

              </div>
          }
          </div>
        </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Emailverify));
