
let url = "http://52.41.234.216:8082";
let stripe_key = "pk_test_WA28gHDk9ny2SiuD1FrFQ567007gwPOA4o";
if(window.location.hostname == "dashboard.erewhonmarket.com"){
    url = "https://dashboardapis.erewhonmarket.com";
    stripe_key = "pk_live_9pRKJ5BrgPzdLzXaE9ieKq02000zSeyTAh";
}else {
    url = "https://dashboardapis.erewhonmarket.com";
    //url = "https://af37-2409-4080-919b-3ca0-28ba-7609-a2e7-507a.ngrok-free.app";
    stripe_key = "pk_test_WA28gHDk9ny2SiuD1FrFQ567007gwPOA4o";
}

export const API_URL = url;

export const STRIPE_KEY =  stripe_key;

export const LIVE_API_URL = "http://apis.erewhonmarket.com:8089";

const ecrs_token  =  localStorage.getItem('ecrs_token');

export let custom_ecrs_id;
export function updateEcrsName(id){
        if(id !== undefined && id !== ""){
            custom_ecrs_id = id;
        }
}
export function convertToDecimal(text){
    return text = parseFloat(text).toFixed(2);
}

export const CONFIG_WITHOUT_TOKEN = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + ecrs_token,
  }
}
export const CONFIG_WITHOUT_TOKEN_BRAND = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

const token = localStorage.getItem('token');

export const  CONFIG_WITH_TOKEN = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': 'Bearer ' + token,
  }
}

export const WEEKS_ARRAY = [
        {key:"4",value:"Last 4 weeks"},
        // {key:"8",value:"Last 8 weeks"}
    ];

export const ALL_STORES = [
                        {
                            key:"",
                            value:"All Stores"
                        },{
                            key:"5",
                            value:"Calabasas"
                        },
                        {
                            key:"8",
                            value:"Los Angeles"
                        },
                        {
                            key:"4027",
                            value:"Pacific Palisades"
                        },
                        {
                            key:"4014",
                            value:"Santa Monica"
                        },
                        {
                            key:"6035",
                            value:"Silver Lake"
                        },
                        {
                            key:"7",
                            value:"Venice"
                        },
                        {
                            key:"7058",
                            value:"Studio City"
                        },
                        {
                            key:"9072",
                            value:"Beverly Hills"
                        },
                        {
                            key:"9076",
                            value:"Culver City"
                        },
                        {
                            key:"10081",
                            value:"Pasadena"
                        }
];

export const BRAND_TYPE = [
    {
        key:"brand2brand",
        value:"Brand to Brand"
    },
    // {
    //     key:"brand2item",
    //     value:"Brand to Item"
    // },
    // {
    //     key:"item2item",
    //     value:"Item to Item"
    // }
]

export const CHECK_USER_TYPE_AND_STATUS = () =>  {
    let user_first_name = localStorage.getItem('user_first_name');
    let user_last_name = localStorage.getItem('user_last_name');
    let company_name = localStorage.getItem('company_name');
    let user_type = localStorage.getItem('user_type');
    let company_review_status = localStorage.getItem('company_status_review');
    let company_status = localStorage.getItem('company_status_review');
  let redirect_type = "company";
  if(user_type == "company"){
      redirect_type =  "company";

   }else if(user_type == "admin"){
        redirect_type = "admin";

   }
   return redirect_type;
}
