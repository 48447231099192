/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { DropdownCustomToggler, DropdownMenu2 } from "../../dropdowns";
import { useHtmlClassService } from "../../../layout";

export function MixedWidget16({ className, chartColor = "danger",weeklysales}) {
let weeklysales_key = [];
if(weeklysales !== undefined && weeklysales !== ""){
     weeklysales_key = Object.keys(weeklysales);
}
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${chartColor}`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${chartColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, chartColor,weeklysales]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_16_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps,weeklysales,weeklysales_key);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div
        className={`card card-custom bg-dark ${className}`}
      >
        {/* begin::Header */}

        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column p-0">
          {/* begin::Chart */}
          <div
            id="kt_mixed_widget_16_chart"
            data-color={chartColor}
            style={{ height: "300px" }}
          />
          {/* end::Chart */}

          {/* begin::Stats */}

          {/* end::Stats */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );
}

function getChartOption(layoutProps,weeklysales,weeklysales_key) {
  let weeklysales_val;
  if(weeklysales !== undefined && weeklysales !== ""){
      weeklysales_val = Object.values(weeklysales);
  }

  let sales_array = [];
  if(weeklysales !== "" && weeklysales !== undefined){
    Object.values(weeklysales).map((value,index)=>{
          sales_array.push(parseInt(value));
    })
  }
console.log(sales_array);
  const options = {
    series: [
      {
        name: "Weekly",
        data: sales_array.reverse(),
      },

    ],
    chart: {
      toolbar: {
       show: false,
     },
      type: "line",
      height: "300px",
      stacked: false

    },
    plotOptions: {
    bar: {
      columnWidth: "20%"
    }
  },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {

              categories: weeklysales_key.reverse(),
              labels:{
                style: {
                  colors: "#ffffff"
                }
              },
            },


    colors: ["#ffffff", "#ffffff"],
    yaxis:[
      {
      labels: {
        style: {
          colors: "#ffffff"
      },
        formatter: function(val, index) {
            return parseInt(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
      }
    },
    ],
    grid: {
      borderColor: "#ffffff",
      strokeDashArray: 3,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 10,
        right: 10,
      },
    },
  };
  return options;
}
